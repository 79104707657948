<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row no-gutters>
                <v-col class="pt-1 pl-7 text-h5 font-weight-bold" v-if="breadCrumbs.length == 0">메뉴 추가</v-col>
                <v-col class="pt-1 pl-7 text-h5 font-weight-bold" v-else>하위 메뉴 추가</v-col>
            </v-row>        
        </v-card-title>

        <v-card-text class="pb-0 pl-7 pr-7 pt-3">
            <v-row no-gutters>
                <v-col class="pb-0 title black--text">
                    메뉴명 :                 
                    <br/>                    
                    <span class="body-2 grey--text">추가하려는 메뉴의 이름을 정해주세요</span>
                    <br/>
                    <template v-for="(breadCrumbItem, breadCrumbIdx) in breadCrumbs">
                        <span class="body-2 grey--text" :key="'bread_crumb'+breadCrumbIdx"> {{breadCrumbItem}} </span>
                        <v-icon :key="'bread_crumb_delim_'+breadCrumbIdx" size="10" class="ml-2 mr-2">mdi-greater-than</v-icon>
                    </template>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col class="pt-2 pb-0">
                    <v-text-field outlined hide-details :error="formStatus['menu_name'].err" :ref="'menu_name'" placeholder="메뉴명을 입력해 주세요" v-model="formData.menu_name" @keyup="checkChanged('menu_name')" @change="checkChanged('menu_name', true)">
                        <template v-slot:append v-if="formStatus['menu_name'].err_msg">
                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                {{formStatus['menu_name'].err_msg}}
                            </div>
                        </template>
                    </v-text-field>                    
                </v-col>
            </v-row>
            <v-divider class="mt-7 mb-2 pb-0"></v-divider>
            <v-row no-gutters class="mb-2">
                <v-col cols="12" class="pt-0 pb-0 title black--text">
                    <v-checkbox label="콜리에게 전화연결하기" hide-details v-model="formData.ring_group_yn" true-value="Y" false-value="N" :error="formStatus.ring_group_yn.err" @change="checkChanged('ring_group_yn')"></v-checkbox>                               
                </v-col>
                <v-col cols="12" class="grey--text">
                    <ul>
                        <li>체크박스 선택 시 등록한 콜리 목록이 보여집니다.</li>
                        <li>목록에서 원하시는 콜리를 선택하면 전화연결 메뉴가 생성됩니다. (다수 선택도 가능합니다.)</li>
                        <li>전화연결 메뉴는 하위메뉴를 추가할 수 없습니다.</li>
                    </ul>
                </v-col>
            </v-row>
            <v-divider class="mt-3 pb-3" v-if="formData.ring_group_yn == 'Y'"></v-divider>
            <v-row no-gutters class="pb-5" v-if="formData.ring_group_yn == 'Y'">
                <v-col cols="12" class="pb-0 title black--text">                    
                    <div style="overflow-y:auto; max-height:200px; border:1px solid #eeeeee; ">
                        <v-list>
                            <v-list-item-group multiple v-model="checkedList">
                                <template  v-for="(staffItem, staffIdx) in allStaffList">
                                    <v-divider v-if="staffIdx > 0" :key="'divider_' + staffIdx"></v-divider>
                                    <v-list-item v-bind:key="staffIdx" :value="staffItem.user_seqno">
                                        <template v-slot:default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox
                                                :input-value="active"
                                                color="blue accent-4"
                                                ></v-checkbox>
                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="staffItem.user_name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>   
                                    </v-list-item>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters class="pb-5" v-if="formData.ring_group_yn == 'Y' && checkedList.length > 0">
                <v-col cols="12">
                    <v-chip v-for="(staffItem, staffIdx) in checkedStaffChips" :key="'checked_' + staffIdx" close class="mr-2 mb-2" color="blue accent-2" text-color="white" @click="uncheckStaff(staffItem)">{{staffItem.user_name}}</v-chip>
                </v-col>
            </v-row>
        </v-card-text>        
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-3 pt-3 text-right block-bt">
                    <v-btn class="ma-2" width="130px" color="primary" text  v-on:click="closePopup()"><span style="color:#555555; font-size:1.4em;">취소</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="submitPopup()"><span style="font-size:1.4em;">추가</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
    
</template>

<script>
import svcStaffApi from '@/api/svc_staff'
import svcVoiceScnApi from '@/api/svc_voice_scn'
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        formData: {
            menu_name: '',
            ring_group_yn: 'N',
        },        
        formStatus: {
            'menu_name': {
                err: false,
                err_msg: '',
            },
            'ring_group_yn' : {
                err: false,
                err_msg: ''
            }
        },
        allStaffList: [],
        checkedList: []
    }),
    computed: {
        ...mapGetters({
          menuList: 'svcVoiceScn/getMenuList',
          parentMenu: 'svcVoiceScn/getSelectedMenu',
        }),        
        checkedStaffChips () { 
            let ret = []

            let va = this
            this.checkedList.forEach(item => { 
                va.allStaffList.forEach(staffItem => { 
                    if(item == staffItem.user_seqno) { 
                        ret.push(staffItem)
                    }
                })
            })

            return ret
        },
        breadCrumbs () { 
            let ret = []
            
            let selectedMenu = this.parentMenu
            if(selectedMenu) { 
                let menuHist = [selectedMenu]

                for(let i=0;i<selectedMenu.menu_depth;i++){
                    let lastMenu = menuHist[menuHist.length-1]
                    let parentMenu = null
                    if(lastMenu.parent_menu_seqno > 0) { 
                        this.menuList.forEach(menuItem => {
                            if(menuItem.menu_seqno == lastMenu.parent_menu_seqno) { 
                                parentMenu = menuItem
                            }
                        })
                    }
                    if(parentMenu) { 
                        menuHist.push(parentMenu)
                    } else {
                        break
                    }
                }

                for(let i=menuHist.length-1;i>=0;i--){
                    ret.push(menuHist[i].menu_name)
                }
            }

            return ret
        },         
    },    
    mounted() {
        let va = this
        svcStaffApi.getList({}, (staffList) => { 
            if(staffList && staffList.length > 0) { 
                staffList.forEach(item => { 
                    va.allStaffList.push(item)
                })
            }        
        }, null, this)
    },
    methods: {
        closePopup() { 
            this.$emit('cancel')
        },
        submitPopup() { 
            if(this.checkFormValid() == false){ return }

            if(this.formData.ring_group_yn == 'Y' && this.checkedList.length == 0) {
                this.$store.dispatch('openAlert', {
                    message: '상담원을 1명 이상 선택해야 합니다',
                    sub_message: '상담원과 통화연결하려면 상담원을 선택해 주세요'
                })           
                return
            } 

            let checkedStaffs = this.checkedStaffChips
            if(this.formData.ring_group_yn == 'Y' && this.checkedList.length == 1 && this.formData.menu_name == '') { 
                if(checkedStaffs.length > 0) {
                    this.formData.menu_name = checkedStaffs[0].user_name
                }
            }

            let reqMenu = {
                parent_menu_seqno: (this.parentMenu ? this.parentMenu.menu_seqno : 0),
                menu_name: this.formData.menu_name,
                menu_type: this.formData.ring_group_yn == 'Y' ? 'RING_GROUP' : 'FOLDER',
                staff_list: [],
            }
            if(checkedStaffs && checkedStaffs.length > 0) { 
                checkedStaffs.forEach((item, idx) => { 
                    reqMenu.staff_list.push({
                        user_seqno: item.user_seqno,
                        user_name: item.user_name,
                        mng_memo: item.mng_memo,
                        user_order: idx+1,
                    })
                })
            }

            let va = this

            svcVoiceScnApi.addMenu(reqMenu, () => {
                va.$store.dispatch('openAlert', {
                    va: va,
                    message: '메뉴가 등록 되었습니다',
                    closeCb: (va) => {
                        va.$emit('submit')
                    }
                })
            }, (err) => {
                va.$store.dispatch('openAlert', {
                    message: '메뉴 등록을 실패 했습니다',
                    sub_message: err,
                })
            }, this)
        },
        checkChanged (t, focusOutFlag) {            
            if(this.formStatus[t]) { 
                if(this.formStatus[t].err || focusOutFlag) { 
                    this.checkFormValid(t)
                }
            }
        },
        checkFormValid (t) { 
            let succ = true
            if(!t || t == 'menu_name') { 
                if(this.checkedList.length != 1 && this.formData.menu_name == '') { 
                    this.formStatus.menu_name.err = true
                    this.formStatus.menu_name.err_msg = '메뉴명을 입력해 주세요'
                    succ = false
                } else {
                    this.formStatus.menu_name.err = false
                    this.formStatus.menu_name.err_msg = ''
                }
            }
            return succ
        },
        uncheckStaff (item) { 
            let idx = this.checkedList.indexOf(item.user_seqno)
            if(idx >= 0) { 
                this.checkedList.splice(idx, 1)
            }
        }        
    }
}
</script>
